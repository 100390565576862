export default {
  namespaced: true,
  state: {
    list_Account_Type: null,
    list_Account_List: null,
    list_ListType: null,
    list_InEx_Status: null,
  },
  getters: {
    _list_Account_Type: (state) => state.list_Account_Type,
    _list_Account_List: (state) => state.list_Account_List,
    _list_ListType: (state) => state.list_ListType,
    _list_InEx_Status: (state) => state.list_InEx_Status,
    _list_InEx_Status: (state) => state.list_InEx_Status,

  },
  mutations: {
  },
  actions: {

    _list_Cooperation_Unit({ state }) {
      return window.axios
        .post("finance/_list_Cooperation_Unit")
        .then((res) => (state.list_Cooperation_Unit = res.data));
    },
    _list_Cooperation_Payment({ state }, params) {
      return window.axios
        .post("finance/_list_Cooperation_Payment", params)
        .then((res) => (state.list_Cooperation_Payment = res.data));
    },
    _list_Account_Type({ state }) {
      return window.axios
        .post("finance/_list_Account_Type")
        .then((res) => (state.list_Account_Type = res.data));
    },
    // ------------------------- Account_Type
    _list_Account_Type({ state }, params) {
      return window.axios
        .post("finance/_list_Account_Type")
        .then((res) => (state.list_Account_Type = res.data));
    },
    _insert_Account_Type({ state }, params) {
      return window.axios
        .post("finance/_insert_Account_Type", params)
        .then((res) => (res.data));
    },
    _update_Account_Type({ state }, params) {
      return window.axios
        .post("finance/_update_Account_Type", params)
        .then((res) => (res.data));
    },
    _delete_Account_Type({ state }, params) {
      return window.axios
        .post("finance/_delete_Account_Type", params)
        .then((res) => (res.data));
    },
    // ------------------------- Account_Type
    // ------------------------- ListType
    _list_InEx_Status({ state }) {
      return window.axios
        .post("finance/_list_InEx_Status")
        .then((res) => (state.list_InEx_Status = res.data));
    },
    _list_ListType({ state }, params) {
      return window.axios
        .post("finance/_list_ListType", params)
        .then((res) => (state.list_ListType = res.data));
    },
    _insert_ListType({ state }, params) {
      return window.axios
        .post("finance/_insert_ListType", params)
        .then((res) => (res.data));
    },
    _update_ListType({ state }, params) {
      return window.axios
        .post("finance/_update_ListType", params)
        .then((res) => (res.data));
    },
    _delete_ListType({ state }, params) {
      return window.axios
        .post("finance/_delete_ListType", params)
        .then((res) => (res.data));
    },
    // ------------------------- ListType
    // ------------------------- Account_List
    _list_Account_List({ state }, params) {
      return window.axios
        .post("finance/_list_Account_List", params)
        .then((res) =>state.list_Account_List = res.data);
    },
    _insert_Account_List({ state }, params) {
      return window.axios
        .post("finance/_insert_Account_List", params)
        .then((res) => (res.data));
    },
    _update_Account_List({ state }, params) {
      return window.axios
        .post("finance/_update_Account_List", params)
        .then((res) => (res.data));
    },
    _delete_Account_List({ state }, params) {
      return window.axios
        .post("finance/_delete_Account_List", params)
        .then((res) => (res.data));
    },
    // ------------------------- Account_List
  },
};
